.swiper-button-next, .swiper-button-prev{
  --swiper-theme-color: white;
  --swiper-navigation-size: 60px;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

body {
  overflow-x: hidden;
}